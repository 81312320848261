// Overrides
@if $bulmaswatch-import-font {
  @import url("https://fonts.googleapis.com/css?family=Ubuntu:400,700&display=swap");
}

.button {
  &.is-active,
  &:active {
    box-shadow: inset 1px 1px 4px rgba($grey-darker, 0.3);
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      &.is-hovered,
      &:hover {
        background-color: darken($color, 10);
      }

      &.is-active,
      &:active {
        box-shadow: inset 1px 0 3px rgba($grey-darker, 0.3);
        background-color: darken($color, 10);
      }
    }
  }
}

.input,
.textarea {
  box-shadow: none;
}

.notification {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      a:not(.button) {
        color: $color-invert;
        text-decoration: underline;
      }
    }
  }
}

.navbar {
  @include touch {
    .navbar-menu {
      background-color: inherit;
    }
  }

  @include desktop {
    .navbar-dropdown .navbar-item {
      color: $text;

      &.is-active {
        background-color: $navbar-dropdown-item-hover-background-color;
      }
    }
  }

  &.is-transparent {
    background-color: transparent;
    .navbar-item,
    .navbar-link {
      color: $text;
    }
  }

  .navbar-link::after {
    border-color: currentColor;
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      @include touch {
        .navbar-item,
        .navbar-link {
          color: $color-invert;
        }
      }
    }
  }
}

.hero {
  .navbar {
    .navbar-item,
    .navbar-link {
      color: inherit;
    }

    @include desktop {
      .navbar-dropdown .navbar-item {
        color: $text;
      }
    }
  }
}
